:root {
  --clr-primary: #060e9f;
  --clr-white: #ffffff;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.btn.error-btn{
  background-color:#060e9f;
  border:1px solid #060e9f;
  color:#fff;
  padding:8px 20px;
  transition: all 0.3s ease-out;
  border-radius:5px;
  font-size:14px;
}

.btn.error-btn:hover{
  background-color:#1169e3;
  border:1px solid #1169e3;
  color:#fff;
}

.error-page{
  min-height:80vh;
  background-color:#f5f5f5;
  text-align:center;
  padding:50px 20px;
  display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.error-page h1{
  font-size:5rem;
  font-family:'poppinsbold';
  color:#1169e3;
  margin:0px;
}

.error-page h1 img{max-height:100px;margin-right:10px;}

.error-page h5{
  font-size: 2rem;
    font-family: 'poppinsbold';
    color: #333;
    margin: 15px 0px;
}

.error-page p{font-size:18px;font-family:'poppins_semiboldregular';color:#666;}

.text-center{
  text-align:center !important;
}

.text-right{
  text-align:right !important;
}

.text-left{
  text-align:left !important;
}

.mr-1{
  margin-right:0.25rem !important;
}
.mr-2{
  margin-right:0.5rem !important;
}
.mr-3{
  margin-right:1rem !important;
}
.mr-4{
  margin-right:1.5rem !important;
}

.ml-1{
  margin-left:0.25rem !important;
}
.ml-2{
  margin-left:0.5rem !important;
}
.ml-3{
  margin-left:1rem !important;
}
.ml-4{
  margin-left:1.5rem !important;
}

.mt-1{
  margin-top:0.25rem !important;
}
.mt-2{
  margin-top:0.5rem !important;
}
.mt-3{
  margin-top:1rem !important;
}
.mt-4{
  margin-top:1.5rem !important;
}

.mb-1{
  margin-bottom:0.25rem !important;
}
.mb-2{
  margin-bottom:0.5rem !important;
}
.mb-3{
  margin-bottom:1rem !important;
}
.mb-4{
  margin-bottom:1.5rem !important;
}

.pr-0{padding-right:0rem !important;}

.pl-0{padding-left:0rem !important;}

.pr-1{
  padding-right:0.25rem !important;
}
.pr-2{
  padding-right:0.5rem !important;
}
.pr-3{
  padding-right:1rem !important;
}
.pr-4{
  padding-right:1.5rem !important;
}

.pl-1{
  padding-left:0.25rem !important;
}
.pl-2{
  padding-left:0.5rem !important;
}
.pl-3{
  padding-left:1rem !important;
}
.pl-4{
  padding-left:1.5rem !important;
}

.pt-1{
  padding-top:0.25rem !important;
}
.pt-2{
  padding-top:0.5rem !important;
}
.pt-3{
  padding-top:1rem !important;
}
.pt-4{
  padding-top:1.5rem !important;
}

.pb-1{
  padding-bottom:0.25rem !important;
}
.pb-2{
  padding-bottom:0.5rem !important;
}
.pb-3{
  padding-bottom:1rem !important;
}
.pb-4{
  padding-bottom:1.5rem !important;
}

@media screen and (min-width:576px){
  .text-sm-right {text-align: right!important;}
  .text-sm-left {text-align: left!important;}
}

@media screen and (min-width:768px){
  .text-md-right {text-align: right!important;}
  .text-md-left {text-align: left!important;}
}

@media screen and (min-width:992px){
  .text-lg-right {text-align: right!important;}
  .text-lg-left {text-align: left!important;}
}

@media screen and (min-width:1200px){
  .text-xl-right {text-align: right!important;}
  .text-xl-left {text-align: left!important;}
}

@media screen and (max-width:480px){
  .hidden-xs{display:none!important}
  .xs-pr-0{padding-right:0!important}
  .xs-pr-1{padding-right:.25rem!important}
  .xs-pr-2{padding-right:.5rem!important}
  .xs-pr-3{padding-right:1rem!important}
  .xs-pr-4{padding-right:1.5rem!important}
  .xs-pl-0{padding-left:0!important}
  .xs-pl-1{padding-left:.25rem!important}
  .xs-pl-2{padding-left:.5rem!important}
  .xs-pl-3{padding-left:1rem!important}
  .xs-pl-4{padding-left:1.5rem!important}
  .xs-mb-0{margin-bottom:0!important}
  .xs-mb-1{margin-bottom:.25rem!important}
  .xs-mb-2{margin-bottom:.5rem!important}
  .xs-mb-3{margin-bottom:1rem!important}
  .xs-mb-4{margin-bottom:1.5rem!important}
  .xs-mt-0{margin-top:0!important}
  .xs-mt-1{margin-top:.25rem!important}
  .xs-mt-2{margin-top:.5rem!important}
  .xs-mt-3{margin-top:1rem!important}
  .xs-mt-4{margin-top:1.5rem!important}
}

@media screen and (min-width:481px) and (max-width:767px){
  .hidden-sm{display:none!important}
  .sm-pr-0{padding-right:0!important}
  .sm-pr-1{padding-right:.25rem!important}
  .sm-pr-2{padding-right:.5rem!important}
  .sm-pr-3{padding-right:1rem!important}
  .sm-pr-4{padding-right:1.5rem!important}
  .sm-pl-0{padding-left:0!important}
  .sm-pl-1{padding-left:.25rem!important}
  .sm-pl-2{padding-left:.5rem!important}
  .sm-pl-3{padding-left:1rem!important}
  .sm-pl-4{padding-left:1.5rem!important}
  .sm-mb-0{margin-bottom:0!important}
  .sm-mb-1{margin-bottom:.25rem!important}
  .sm-mb-2{margin-bottom:.5rem!important}
  .sm-mb-3{margin-bottom:1rem!important}
  .sm-mb-4{margin-bottom:1.5rem!important}
  .sm-mt-0{margin-top:0!important}
  .sm-mt-1{margin-top:.25rem!important}
  .sm-mt-2{margin-top:.5rem!important}
  .sm-mt-3{margin-top:1rem!important}
  .sm-mt-4{margin-top:1.5rem!important}
}

@media screen and (min-width:768px) and (max-width:991px){
  .md-pr-0{padding-right:0!important}
  .md-pr-1{padding-right:.25rem!important}
  .md-pr-2{padding-right:.5rem!important}
  .md-pr-3{padding-right:1rem!important}
  .md-pr-4{padding-right:1.5rem!important}
  .md-pl-0{padding-left:0!important}
  .md-pl-1{padding-left:.25rem!important}
  .md-pl-2{padding-left:.5rem!important}
  .md-pl-3{padding-left:1rem!important}
  .md-pl-4{padding-left:1.5rem!important}
  .md-mb-0{margin-bottom:0!important}
  .md-mb-1{margin-bottom:.25rem!important}
  .md-mb-2{margin-bottom:.5rem!important}
  .md-mb-3{margin-bottom:1rem!important}
  .md-mb-4{margin-bottom:1.5rem!important}
  .md-mt-0{margin-top:0!important}
  .md-mt-1{margin-top:.25rem!important}
  .md-mt-2{margin-top:.5rem!important}
  .md-mt-3{margin-top:1rem!important}
  .md-mt-4{margin-top:1.5rem!important}
  }
  
  @media screen and (min-width:992px) and (max-width:1199px){
    .lg-pr-0{padding-right:0!important}
    .lg-pr-1{padding-right:.25rem!important}
    .lg-pr-2{padding-right:.5rem!important}
    .lg-pr-3{padding-right:1rem!important}
    .lg-pr-4{padding-right:1.5rem!important}
    .lg-pl-0{padding-left:0!important}
    .lg-pl-1{padding-left:.25rem!important}
    .lg-pl-2{padding-left:.5rem!important}
    .lg-pl-3{padding-left:1rem!important}
    .lg-pl-4{padding-left:1.5rem!important}
    .lg-mb-0{margin-bottom:0!important}
    .lg-mb-1{margin-bottom:.25rem!important}
    .lg-mb-2{margin-bottom:.5rem!important}
    .lg-mb-3{margin-bottom:1rem!important}
    .lg-mb-4{margin-bottom:1.5rem!important}
    .lg-mt-0{margin-top:0!important}
    .lg-mt-1{margin-top:.25rem!important}
    .lg-mt-2{margin-top:.5rem!important}
    .lg-mt-3{margin-top:1rem!important}
    .lg-mt-4{margin-top:1.5rem!important}
  }
  
  @media screen and (min-width:1200px){
    .xl-pr-0{padding-right:0!important}
    .xl-pr-1{padding-right:.25rem!important}
    .xl-pr-2{padding-right:.5rem!important}
    .xl-pr-3{padding-right:1rem!important}
    .xl-pr-4{padding-right:1.5rem!important}
    .xl-pl-0{padding-left:0!important}
    .xl-pl-1{padding-left:.25rem!important}
    .xl-pl-2{padding-left:.5rem!important}
    .xl-pl-3{padding-left:1rem!important}
    .xl-pl-4{padding-left:1.5rem!important}
    .xl-mt-0{margin-top:0!important}
    .xl-mt-1{margin-top:.25rem!important}
    .xl-mt-2{margin-top:.5rem!important}
    .xl-mt-3{margin-top:1rem!important}
    .xl-mt-4{margin-top:1.5rem!important}
    .xl-mb-0{margin-bottom:0!important}
    .xl-mb-1{margin-bottom:.25rem!important}
    .xl-mb-2{margin-bottom:.5rem!important}
    .xl-mb-3{margin-bottom:1rem!important}
    .xl-mb-4{margin-bottom:1.5rem!important}
  }

