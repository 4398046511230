.hotel-info{
    padding: 30px 0px;
}
.page-bg {
    background-color: #f1f1f1;
  }

.hotel-img-gal .img-sub .sub-img-lst-holder{
    position: relative;
}

.hotel-img-gal .img-sub .sub-img-lst-holder .btn-show-imgs{
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: 0;
    background-color: #0000009e;
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
}

.hotel-img-gal .img-master img, .hotel-img-gal .img-sub img{
    width: 100%;
    border-radius: 6px;
}

.hotel-img-gal .img-master img{
    max-height: 305px;
    min-height: 305px;
    object-fit: cover;
}

.hotel-img-gal .img-sub-small{
    max-height: 119px;
    min-height: 119px;
    object-fit: cover;
}

.hotel-img-gal .img-sub-big{
    max-height: 170px;
    min-height: 170px;
    object-fit: cover;
}

.hotel-info .hotel-name{
    font-size: 23px;
}

.hotel-info .hotel-adrs{
    font-size: 14px;
}

.hotel-info .fac-badge{
    margin-bottom: 15px;
}


.hotel-info .fac-badge .badge{
    margin-right: 10px;
}


.hotel-info .fac-badge .badge.badge-rating{
    background-color: #fff2ee;
    padding: 6px 20px;
    color: #ff5e23;
    border-radius: 6px;
    border: 1px solid #ff5e23;
}


.hotel-info .fac-badge .badge.badge-wifi{
    background-color: #32a923;
    padding: 5px 10px;
    color: #fff;
    border-radius: 6px;
}



.hotel-info .info-left .trip-adv-rating img{
    margin-left: -8px;
}

.hotel-info .info-left .trip-adv-rating p{
    font-size: 12px;
}

.hotel-info .info-left .trip-adv-rating p span{
    color: #007bff;
    font-size: 12px;
    margin-left: 5px;
}

.hotel-info .info-left .map-view button{
    background-color: transparent;
    border: 0;
    font-size: 14px;
    text-decoration: underline;
}

.hotel-info .info-left .map-view button svg{
    margin-right: 10px;
}



.hotel-info .info-right .price{
    font-size: 18px;
    font-weight: 600;
    color: var(--clr-primary);
}

.hotel-info .info-right .per-night{
    font-size: 13px;
}

.hotel-info .info-right .price-per-night{
    font-size: 15px;
    font-weight: 500;
}

.hotel-info .info-right .inc-tax{
    font-size: 13px;
    margin: 0;
}

.hotel-info .info-right .btn-ch-room{
    font-size: 13px;
    margin-top: 15px;
    background-color: var(--clr-primary);
    border: 0;
    font-weight: 500;
}


.card-common{
    border: 0 !important;
}

.card-common .title{
    font-size: 16px;
}


.prop-highlite .hlt-item{
    background-color: #e7e7e7;
    border-radius: 6px;
    padding: 10px 15px;
    padding-left: 55px;
    position: relative;
    margin-top: 15px;
}

.prop-highlite .hlt-item .name{
    font-size: 14px;
    margin-bottom: 2px;
}

.prop-highlite .hlt-item .desc{
    margin: 0;
    font-size: 13px;
}

.prop-highlite .hlt-item svg{
    position: absolute;
    left: 10px;
    top: 13px;
    font-size: 29px;
}


.nrb-landmarks .scroll-container{
    max-height: 285px;
    min-height: 285px;
    overflow-y: scroll;
    overflow-x: hidden;
}


.nrb-landmarks .scroll-container .landmark-grp {
    margin-top: 15px;
}

.nrb-landmarks .scroll-container .landmark-grp .heading {
    background-color: #ededed;
    padding: 5px 10px;
}

.nrb-landmarks .scroll-container .landmark-grp .heading .title {
    margin: 0;
    font-size: 15px;
}

.nrb-landmarks .scroll-container .landmark-grp .list{
    padding-left: 27px;
    padding-top: 10px;
}

.nrb-landmarks .scroll-container .landmark-grp .list .item{
    font-size: 14px;
    margin-bottom: 4px;
}




.fac-section .fac-card .fac-title{
    font-size: 16px;
    margin-bottom: 15px;
}



.fac-section .fac-card .fac-row .fac-item-title{
    font-size: 15px;
}



.fac-section .fac-card .fac-row .fac-ico-col{
    border-right: 1px solid #c3c3c3;
}

.fac-section .fac-card .fac-row .fac-item .fac-item-text{
    font-size: 14px;
    font-weight: 400;
}

.fac-section .fac-card .fac-row .fac-item .fac-item-sub-text{
    font-size: 14px;
    font-weight: 400;
}




.section-room-options .room-option-filter .title{
    font-size: 16px;
}



.section-room-options .room-listing .title{
    font-size: 16px;
}

.section-room-options .room-listing .room-item{
    border: 1px solid #ddd;
    border-radius: 6px;
}

.section-room-options .room-listing .room-item .img-thumb-holder{
    overflow: hidden;
}

.section-room-options .room-listing .room-item .img-thumb-holder .img-thumb{
    width: 100%;
    max-height: 180px;
    min-height: 180px;
    object-fit: cover;
    border-radius: 6px 6px 0px 0px;
}

.section-room-options .room-listing .room-item .room-info-holder{
    padding: 15px 10px;
}   

.section-room-options .room-listing .room-item .room-info-holder .title{
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.section-room-options .room-listing .room-item .room-info-holder .guest-count{
    font-size: 14px;
    color: #6c6c6c;
}



.section-room-options .room-listing .room-item .room-info-holder .price-inc .title{
    font-size: 14px;
    font-weight: 400;
}

.section-room-options .room-listing .room-item .room-info-holder .price-inc .list{
    padding: 0;
    margin: 0;
    list-style: none;
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto;
}

.section-room-options .room-listing .room-item .room-info-holder .price-inc .list .item{
    font-size: 14px;
}

.section-room-options .room-listing .room-item .room-info-holder .price-inc .list .item svg{
    color: #32a923;
    margin-right: 5px;
}

.section-room-options .room-listing .room-item .room-info-holder .item-footer{
    margin-top: 15px;
}

.section-room-options .room-listing .room-item .room-info-holder .item-footer .old-price{
    font-size: 14px;
    color: #919191;
    text-decoration: line-through;
    margin-bottom: 0;
}

.section-room-options .room-listing .room-item .room-info-holder .item-footer .price{
    font-size: 19px;
    color: var(--clr-primary);
}

.section-room-options .room-listing .room-item .room-info-holder .item-footer .btn-book-room{
    width: 100%;
    font-size: 14px;
    background-color: var(--clr-primary);
    border: 0;
}   

.section-room-options .room-listing .room-item .room-info-holder .item-footer .cancell-plcy{
    font-size: 13px;
    color: #ed1c24;
    margin: 0;
}


.reviews-section  .trip-avd-review .title-trip-adv{
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 18px;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 6px;
    margin-top: 25px;
}

.reviews-section  .trip-avd-review .content{
    border: 1px solid #d3d3d3;
    padding: 15px 10px;
    border-radius: 6px;
}



.reviews-section  .trip-avd-review .content .rating .box{
    height: 60px;
    width: 60px;
    background-color: #139e09;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.reviews-section  .trip-avd-review .content .rating .box p{
    margin: 0;
    font-size: 19px;
}

.reviews-section  .trip-avd-review .content .rating .box p span{
    font-size: 13px;
}

.reviews-section  .trip-avd-review .content .rating .text{
    font-size: 14px;
    margin-top: 3px;
}

.reviews-section  .trip-avd-review .content  .progress-info{
    margin-bottom: 5px;
}


.reviews-section  .trip-avd-review .content  .progress-info .text{
    font-size: 14px;
}

.reviews-section  .trip-avd-review .content  .progress-info .value{
    font-size: 14px;
    color: var(--clr-primary);
}




.reviews-section  .trip-avd-review .content .list-info .list-holder .title{
    font-size: 15px;
}

.reviews-section  .trip-avd-review .content .list-info .list-holder .list{
    margin: 0;
    padding: 0;
    list-style: none;
}

.reviews-section  .trip-avd-review .list-info-col{
    border-left: 1px solid #c9c9c9;
}

.reviews-section  .trip-avd-review .content .list-info .list-holder .list .item{
    font-size: 14px;
    margin-bottom: 10px;
}

.reviews-section  .trip-avd-review .content .list-info .list-holder .list .item svg{
    margin-right: 5px;
}


.detail-info-section .detail-info-card .border-box{
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
}


.detail-info-section .detail-info-card .border-box .rating-holder .title-rating{
    font-size: 16px;
    margin-bottom: 15px;
}

.detail-info-section .detail-info-card .border-box .rating-holder .box-rating{
    height: 60px;
    width: 60px;
    background-color: #139e09;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-right: 25px;
}

.detail-info-section .detail-info-card .border-box .rating-holder .box-rating p{
    margin: 0;
    font-size: 19px;
}

.detail-info-section .detail-info-card .border-box .rating-holder .box-rating p span{
    font-size: 13px;
}



.detail-info-section .detail-info-card .border-box .rating-holder .text-rating h4{
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 10px;
}

.detail-info-section .detail-info-card .border-box .rating-holder .text-rating p{
    margin: 0;
    font-size: 13px;
}

.detail-info-section .detail-info-card .border-box .rating-holder .progress-holder{
    margin-top: 20px;
}



.detail-info-section .detail-info-card .border-box .rating-holder .progress-holder .progress-item .text{
    font-size: 14px;
}

.detail-info-section .detail-info-card .border-box .rating-holder .progress-holder .progress-item .value{
    font-size: 14px;
}



.detail-info-section .detail-info-card .border-box .hotel-info-brief .hotel-img-thumb-holder{
    max-height: 250px;
    min-height: 250px;
    position: relative;
}

.detail-info-section .detail-info-card .border-box .hotel-info-brief .hotel-img-thumb{
    max-height: 250px;
    min-height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.detail-info-section .detail-info-card .border-box .hotel-info-brief .hotel-img-thumb-holder .thumb-info{
    position: absolute;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    padding: 15px 20px;
}

.detail-info-section .detail-info-card .border-box .hotel-info-brief .hotel-img-thumb-holder .thumb-info .about{
    font-size: 16px;
}

.detail-info-section .detail-info-card .border-box .hotel-info-brief .hotel-img-thumb-holder .thumb-info .name{
    margin-bottom: 0;
    font-size: 27px;
}

.detail-info-section .detail-info-card .border-box .hotel-info-brief .description{
    padding: 15px 0px;
    padding-bottom: 0;
}

.detail-info-section .detail-info-card .border-box .hotel-info-brief .description p{
    font-size: 14px;
}
