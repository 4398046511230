:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  
  

  .btn.search-btn{
    background-color:#060e9f;
    border:1px solid #060e9f;
    color:#fff;
    padding:8px 20px;
    transition: all 0.3s ease-out;
    width: 200px;
    height: 70px;
    font-size: 18px;
    border-radius: 10px;
  }

  .btn.search-btn:hover{
    background-color:#1169e3;
    border:1px solid #1169e3;
    color:#fff;
  }
 

  .btn.btn--primary{
    background-color:#1169e3;
    border:1px solid #1169e3;
    color:#fff;
    padding:8px 20px;
    transition: all 0.3s ease-out;
  }

  .btn.btn--primary:hover{
    background-color:#060e9f;
    border:1px solid #060e9f;
    color:#fff;
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  

  @media screen and (max-width:480px){
    .btn.search-btn{width:100%}
  }