.my-account{
    padding: 35px 0px;
}

.my-account .nav-pills{

}

.my-account .nav-pills .nav-item{
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    margin: 0 0 5px;
}

.my-account .nav-pills .nav-item a{
    border-radius: 0;
    border-radius: 0;
    font-size: 15px;
    color: #000;
}

.my-account .nav-pills .nav-item a svg{
    margin-right: 5px;
}

.my-account .nav-pills .nav-link.active{
    color: #fff !important;
    background-color: var(--clr-primary);
}

.my-account .tab-content{
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    padding: 15px 20px;
}


.my-account .tab-content .booking-empty{

}

.my-account .tab-content .booking-empty .empty-icon{

}

.my-account .tab-content .booking-empty .empty-icon .icon{
    width: 100%;
}

.my-account .tab-content .booking-empty .empty-content{

}

.my-account .tab-content .booking-empty .empty-content h2{
    font-size: 18px;
}

.my-account .tab-content .booking-empty .empty-content .btn-primary{
    background: var(--clr-primary);
    border: 0;
    font-size: 13px;
    border-radius: 4px;
}

.manage-booking{
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    background-color: #fff;
}

.manage-booking .title-block{
    background-color: #f6fbfc;
    padding: 15px;
}

.manage-booking .title-block .title{
    color: #333;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
}
.manage-booking .form-block{
    padding: 15px;
}
.manage-booking .form-block .form-title{
    font-size: 18px;
}

.manage-booking .form-block .btn-form-submit{
    font-size: 13px;
    background-color: var(--clr-primary);
    border: 0;
    border-radius: 4px;
}


.setting-card{
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    background-color: #fff;
    margin-bottom: 15px;
}

.setting-card .form-control{
    font-size: 14px;
}

.setting-card .title-block{
    background-color: #f6fbfc;
    padding: 15px;
}

.setting-card .title-block .title{
    color: #333;
    font-size: 15px;
    text-transform: capitalize;
    margin: 0;
    font-weight: 500;
}
.setting-card .title-block .subtitle{
    font-size: 13px;
    margin: 0;
}
.setting-card .form-block{
    padding: 15px;
}
.setting-card .form-block .form-title{
    font-size: 13px;
}

.setting-card .form-block .btn-form-submit{
    font-size: 13px;
    background-color: var(--clr-primary);
    border: 0;
    border-radius: 4px;
}

.setting-card .ch-pass-option h5{
    font-size: 14px;
}

.setting-card .ch-pass-option p{
    font-size: 14px;
}   

.setting-card .ch-pass-option .btn-ch-pass{
    font-size: 14px;
    background-color: var(--clr-primary);
    border: 0;
}   


.my-account .setting-menu{

}


.my-account .setting-menu .item{
    line-height: 1.5;
    border-left: 3px solid #1f3c91;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    margin: 0 0 5px;
}

.my-account .setting-menu .item .setting-name{
    display: block;
    padding: 12px 15px;
    color: #000;
    font-size: 14px;
    margin: 0;
}

.my-account .setting-menu .item .setting-name svg{
    margin-right: 5px;
}