.footer-container {
    background-color: #4a4a4a;
    padding: 30px 15px;
  }

  .copy-right-text{
    text-align: center;
    color: #fff;
    border-top: 1px solid #ccc;
    max-width: 1170px;
    margin: auto;
    padding-top: 20px;
    margin-bottom: 0px;
  }

  .footer-links {
    width: 100%;
    max-width: 1170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:0 auto;
  }
  
  .footer-link-wrapper {
    display: grid;
    width:100%;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    text-align: left;
    box-sizing: border-box;
    width:100%;
  }
  
  .footer-link-items h2 {
    margin-bottom: 20px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
    font-size: 18px;
    font-family: 'poppins_mediumregular';
    font-weight: 600;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 12px;
    font-size:14px;
    font-family:poppins_mediumregular;
  }


  .footer-link-items a .fas{margin-right:5px;}
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  

  @media screen and (max-width:480px){
    .footer-link-wrapper{flex-direction: column;}
    .footer-link-items{width:100%}
  }

  @media screen and (min-width:1200px){
    .footer-link-wrapper{grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));}
}