
@font-face {
  font-family: 'poppins_blackregular';
  src: url('./fonts/Poppins/poppins-black-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_blackitalic';
  src: url('./fonts/Poppins/poppins-blackitalic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}




@font-face {
  font-family: 'poppinsbold';
  src: url('./fonts/Poppins/poppins-bold-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsbold_italic';
  src: url('./fonts/Poppins/poppins-bolditalic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_extraboldregular';
  src: url('./fonts/Poppins/poppins-extrabold-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_extrabolditalic';
  src: url('./fonts/Poppins/poppins-extrabolditalic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-extrabolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_extralightregular';
  src: url('./fonts/Poppins/poppins-extralight-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_extralightitalic';
  src: url('./fonts/Poppins/poppins-extralightitalic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-extralightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsitalic';
  src: url('./fonts/Poppins/poppins-italic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_lightregular';
  src: url('./fonts/Poppins/poppins-light-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_lightitalic';
  src: url('./fonts/Poppins/poppins-lightitalic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_mediumregular';
  src: url('./fonts/Poppins/poppins-medium-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'poppins_mediumitalic';
  src: url('./fonts/Poppins/poppins-mediumitalic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-mediumitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsregular';
  src: url('./fonts/Poppins/poppins-regular-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_semiboldregular';
  src: url('./fonts/Poppins/poppins-semibold-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_semibolditalic';
  src: url('./fonts/Poppins/poppins-semibolditalic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-semibolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_thinitalic';
  src: url('./fonts/Poppins/poppins-thinitalic-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-thinitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppins_thinregular';
  src: url('./fonts/Poppins/poppins-thin-webfont.woff2') format('woff2'),
       url('./fonts/Poppins/poppins-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  font-family: 'poppinsregular',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  font-family: sans-serif;
  text-align: center;
}