.hotel-filter{
    padding: 20px 0;
}
.session-exp{
    background: none;
    color: #156ee9 !important;
    border: 2px solid #156ee9 !important;
    width: 100%; 
}
.filter-card{
    background: #fff;
    border-radius: 8px;
    margin-top: 20px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}
.f-content .fa-icon{
    background: #28871c;
    color: #fff;
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 50%;
    margin-right: 10px;
}
.filter-content h5{
    font-weight: bold;
    text-align: left;
}
.filter-content h6{
   font-weight: bold;
}
.popular-filter .form-check{
    width: 100%;
}
.filter-all-link{
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
}
.filter-innerdiv {
    border: 1px solid #126be5;
    padding: 12px;
    padding-top: 12px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.filter-innerdiv:last-child{
    margin-bottom: 0 ;
}
.trip-advisor-filter .list-group-item{
    padding: 10px;
}
.filter-reset-btn{
    background: #060e9f !important;
    border: 1px solid #060e9f !important;
}