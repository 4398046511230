.newsletter-section {
  background-image: url('../../assets/images/news-letter-banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
  background-position: center;
  padding: 75px 0px;
  text-align: center;
  position: relative;
}

.newsletter-container {
  max-width: 1170px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;
}

.cover {
  padding: 75px 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgba(22, 40, 105, .91) 46%, rgba(0, 0, 0, .41) 100%);
}

.footer-input::placeholder {
  color: #777;
}

.input-areas {
  position: relative;
  width: 51%;
  margin: auto;
}

.newsletter-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 15px;
  color: #fff;
  position: relative;
  font-family: 'poppins_mediumregular';
}

.newsletter-subheading {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 40px;
  color: #fff;
  position: relative;
  font-family: 'poppinsregular';
  font-weight: normal;
}

.footer-input {
  padding: 15px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: 1px solid #ccc;
  font-size: 15px;
  margin-bottom: 15px;
  height: 50px;
  width: 68%;
}

.input-areas .btn.btn--primary {
  width: 30%;
  padding: 15px 20px;
  height: 50px;
  font-size: 15px;
}

@media screen and (max-width:767px) {
  .input-areas .btn.btn--primary {
    width: 100%
  }

  .input-areas {
    width: 80%
  }

  .input-areas .footer-input {
    width: 100%
  }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .input-areas {
    width: 90%
  }
}