
.search-dropdown {
    position: relative;
    /* display: inline-block; */
  }
  
  input[type="text"] {
    padding: 8px;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 10;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 245px;
    z-index: 1;
    height: 600px;
    overflow-x: auto;
  }
  
  .dropdown-list li {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-list li:hover {
    background-color: #f2f2f2;
  }