.top-flight-section{
    background-color:#f3f3f3;
    padding:50px 0px;
}

.flight-container{
    max-width:1170px;
    width:100%;
    padding-right:15px;
    padding-left:15px;
    margin:auto;
}

.top-flight-section h2{
    font-size: 2rem;
    font-family: 'poppins_mediumregular';
    text-align: center;
}