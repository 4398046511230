.card-common{
    border: 0 !important;
}

.card-common .title{
    font-size: 16px;
}

.card-common .title-lg{
    font-size: 22px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 15px;
    font-weight: 400;
}

.hotel-checkout{

}

.hotel-checkout .card-booking-info{
    
}


.hotel-checkout .card-booking-info{
    
}

.hotel-checkout .card-booking-info .hotel-profile{
    margin-top: 20px;
}

.hotel-checkout .card-booking-info .hotel-profile .hotel-img-thumb-holder{
    width: 75px;
    height: 75px;
    border-radius: 6px;
    margin-right: 10px;
}

.hotel-checkout .card-booking-info .hotel-profile .hotel-img-thumb-holder .hotel-img-thumb{
    width: 75px;
    height: 75px;
    border-radius: 6px;
}

.hotel-checkout .card-booking-info .hotel-profile .info-holder{

}

.hotel-checkout .card-booking-info .hotel-profile .info-holder .name{
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}

.hotel-checkout .card-booking-info .hotel-profile .info-holder .name span{
    margin-left: 10px;
    font-size: 14px;
    color: #f7b717;
}

.hotel-checkout .card-booking-info .hotel-profile .info-holder .name span svg{
    margin-right: 5px;
}

.hotel-checkout .card-booking-info .hotel-profile .info-holder .adrs{
    font-size: 14px;
    color: #9b9b9b;
}


.ch-in-out-info{
    border-top: 1px dashed var(--clr-primary);
    border-bottom: 1px dashed var(--clr-primary);
    padding: 6px 0px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.ch-in-out-info .ch-lable{
    font-size: 14px;
    margin: 0;
}

.ch-in-out-info .ch-date{
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.ch-in-out-info .night-count{
    background-color: var(--clr-primary);
    padding: 4px 6px;
    border: 1px dashed #fff;
    text-transform: capitalize;
    color: #fff;
    font-size: 14px;
}

.ch-in-out-info .guest-count{
    font-size: 14px;
    margin: 0;
}

.price-inc{
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 10px;
}

.price-inc .title{
    font-size: 18px;
}

.price-inc .sub-title{
    font-size: 13px;
    margin-bottom: 0px;
}

.price-inc .fac .item{
    background-color: #000;
    color: #fff;
    border-radius: 50px;
    padding: 4px 12px;
    display: inline-block;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 5px;
}

.hotel-checkout .cancell-plcy{

}

.hotel-checkout .cancell-plcy .title{
    font-size: 18px;
    margin: 0;
}

.hotel-checkout .cancell-plcy .text{
    font-size: 14px;
}


.card-guest-details .form-control{
    font-size: 14px;
}

.card-guest-details .info{
    font-size: 13px;
}

.card-guest-details .group-label{
    font-size: 14px;
}

.card-guest-details .btn-proceed{
    background: var(--clr-primary);
    font-size: 14px;
    border: 0;
}


.card-price-breakup{

}


.card-price-breakup .sec-exp{
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 10px 10px;
    border-radius: 6px;
    font-size: 14px;
    margin-top: 15px;
}

.card-price-breakup .sec-exp .msg{
    font-size: 14px;
    margin: 0;
}

.card-price-breakup .sec-exp .msg span{
    font-weight: 500;
}

.card-price-breakup .break-up{
    margin-top: 20px;
}

.card-price-breakup .break-up .item{
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.card-price-breakup .break-up .item .label{
    font-size: 14px;
    font-weight: 400;
    color: #666;
    margin: 0;
}

.card-price-breakup .break-up .item .value{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin: 0;
}



.card-price-breakup .summary{
    margin-top: 20px;
}

.card-price-breakup .summary .title{

}

.card-price-breakup .summary .item{
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.card-price-breakup .summary .item .label{
    font-size: 14px;
    font-weight: 400;
    color: #666;
    margin: 0;
}

.card-price-breakup .summary .item .value{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin: 0;
}

.card-price-breakup .sub-total{

}

.card-price-breakup .sub-total .label{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.card-price-breakup .sub-total .value{
    font-size: 18px;
    font-weight: 500;
    color: #dc3545;
    margin: 0;
}