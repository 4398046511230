.modifySection{background-color:#ccc;padding:15px 0px 0px;margin-bottom:20px;}

.modifySection input, .modifySection select{width: 100%;height: 40px;border: 1px solid #e7e8ea;outline: 0px;padding: 5px 10px;border-radius: 10px;font-size: 14px;background-color: #f7f7f7;}

.modifySection .modifybtn{background-color: #060e9f;
    border: 1px solid #060e9f;
    color: #fff;
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    width: 100%;
    height: 40px;
    font-size: 16px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .modifySection label{font-size:13px;margin-bottom:5px;font-weight:500}

    .modifySection .modifybtn:hover {
        background-color: #1169e3;
        border: 1px solid #1169e3;
        color: #fff;
    }

    .modifySection .datesel{
        border-radius:10px 0px 0px 10px;
    }

    .modifySection .timesel{
        border-radius:0px 10px 10px 0px;
    }

    .formcal{
        background-image: url('/src/assets/images/calendar.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        padding-left: 35px !important;
        background-position: 10px 50%;
    }
    
    .formuser{
        background-image: url('/src/assets/images/traveller.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        padding-left: 35px !important;
        background-position: 10px 50%;
    }

    .formmap{
        background-image: url('/src/assets/images/map.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        padding-left: 35px !important;
        background-position: 10px 50%;
    }

    .seatIcon{
        background-image: url('/src/assets/images/seatIcon.svg');
        background-repeat: no-repeat;
        background-size: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .bagIcon{
        background-image: url('/src/assets/images/bagIcon.svg');
        background-repeat: no-repeat;
        background-size: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .doorIcon{
        background-image: url('/src/assets/images/doorIcon.svg');
        background-repeat: no-repeat;
        background-size: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .fuelIcon{
        background-image: url('/src/assets/images/fuelIcon.svg');
        background-repeat: no-repeat;
        background-size: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .acIcon{
        background-image: url('/src/assets/images/acIcon.svg');
        background-repeat: no-repeat;
        background-size: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .transmissionIcon{
        background-image: url('/src/assets/images/transmissionIcon.svg');
        background-repeat: no-repeat;
        background-size: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }


.resultContent{
    min-height:80vh;
    background-color:#f5f5f5;
}

.leftFilterSection{margin-bottom:15px;}

    .leftFilterSection h3{
        font-size: 1.25rem;
    font-weight:600;
    color: #333;
    margin-bottom: 10px;
    }

    .leftFilterSection .resetBtn{background-color:#dc3545;border:1px solid #dc3545;color:#fff;padding:5px 10px;width:100%;box-shadow:none;border-radius: 5px;font-size: 15px;}

    .leftFilterSection .resetBtn:hover{background-color:#c82333;border:1px solid #bd2130;color:#fff}

    .leftFilterSection .filterBox{
        background-color: #fff;
    border: 1px solid rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    border-radius: 10px;
    padding: 15px;
    margin-top: 15px;
    }

    .leftFilterSection .filterBox h5.filterHeading{
        font-size: 1rem;
    font-weight:500;
    margin-bottom: 10px;
    }

    .leftFilterSection .filterBox .filterBody ul.checkbox_List, .leftFilterSection .filterBox .filterBody ul.layover_ul{list-style-type: none;padding:0px;margin:0px;}

    .leftFilterSection .filterBox .filterBody ul.checkbox_List li{display:block;margin-bottom:5px;}

    .leftFilterSection .filterBox .filterBody ul.checkbox_List li:last-child{margin:0px;}

    .leftFilterSection .filterBox .filterBody ul.checkbox_List li label{font-size:13px;margin:0px;color:#333}

    .leftFilterSection .filterBox .filterBody ul.checkbox_List li label input{vertical-align: middle;margin-right:5px;}

    .leftFilterSection .filterBox .filterBody ul.layover_ul li{display: inline-block;margin-right:5px}

    .leftFilterSection .filterBox .filterBody ul.layover_ul li:last-child{margin:0px}

    .leftFilterSection .filterBox .filterBody ul.layover_ul li label{background-color:#f1f1f1;padding:5px 10px;margin:0px;font-size:13px;color:#333;width:75px;display:block;text-align:center;cursor:pointer;border-radius:4px;}

    .leftFilterSection .filterBox .filterBody ul.layover_ul li label.active{background-color:#ddd;}

    .resultsInfo h3{font-size: 1.25rem;font-weight:600;color: #333;margin-bottom: 20px;}

    .carResultBox{
        background-color: #fff;
        border: 1px solid rgb(192, 202, 213);
        box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
        border-radius: 15px;
        padding: 15px 15px;
        margin-bottom: 15px;
    }

    .carResultBox .carImage img{
        display:block;
        margin:0px auto 10px;
        max-height: 200px;
        max-width:100%;
    }

    .carResultBox .carMiddleSection .carName{
        font-size:20px;
        margin-bottom:10px;
        color:#333;
    }

    .carResultBox .carMiddleSection .carName small{font-weight:normal}

    .carResultBox .carMiddleSection .supplierInfo{
        list-style-type:none;padding:0px;margin:0px;
    }

    .carResultBox .carMiddleSection .supplierInfo li{
        display:inline-block;margin-bottom:5px;margin-right:8px;font-size:14px;
    }

    .carResultBox .carMiddleSection .supplierInfo li:last-child{
        margin-right:0px;
    }

    .carResultBox .carMiddleSection .supplierInfo li.supplierLogo img{max-height:18px;max-width:100%}

    .carResultBox .carMiddleSection .supplierInfo li.supplierRating{
        background-color:#ffc107;
        padding:2px 10px;color:#fff;border-radius:4px;font-size:14px;
    }

    .carResultBox .carMiddleSection .carFacilities ul{list-style-type: none;padding:0px;margin:0px;}

    .carResultBox .carMiddleSection .carFacilities ul li{display:inline-block;margin-bottom:5px;margin-right:10px;font-size:14px;}

    .carResultBox .car_price_section{
        display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    }

    .carResultBox .car_price_section p{font-size:15px;font-weight:500;margin-bottom:10px;}

    .carResultBox .car_price_section h4{font-size:20px;font-weight:bold;margin-bottom:10px;}

    .carResultBox .car_price_section .selectBtn{
        background-color: #060e9f;
        border: 1px solid #060e9f;
        color: #fff;
        font-size:14px;
        padding: 5px 20px;
        border-radius: 5px;
    }

    .carResultBox .car_price_section .selectBtn:hover{
        background-color: #1169e3;
        border: 1px solid #1169e3;
        color: #fff;
    }