.search-detail-content{
    min-height:80vh;
    background-color:#f5f5f5;
    padding:30px 0px;
}

.form-cal{
    /* background-image: url(/src/images/calendar.svg); */
    background-repeat: no-repeat;
    background-size: 16px;
    padding-left: 35px !important;
    background-position: 10px 50%;
}

.search-detail-content .search-strip{margin-bottom:15px;}

.search-detail-content .search-strip h5{
    font-size: 1.25rem;
    font-family: 'poppins_semiboldregular';
    color: #333;
}

.search-detail-content .search-strip .back-btn{
    background-color: #060e9f;
    border: 1px solid #060e9f;
    color: #fff;
    font-size:14px;
    border-radius:5px;
    padding:5px 15px;
}

.search-detail-content .search-strip .back-btn:hover{
    background-color: #1169e3;
        border: 1px solid #1169e3;
        color: #fff;
}

.flight-details-box{
    background-color: #fff;
    border: 1px solid rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
}

.flight-details-box .flight-info-heading{margin-bottom:15px;}

.flight-details-box .flight-info-heading h5{
    font-size: 1.2rem;
    font-family: 'poppins_semiboldregular';
    color: #444;
    margin-bottom:5px;
}

.flight-details-box .flight-info-heading ul.dep-ul{list-style-type: none;margin:0px;padding:0px}

.flight-details-box .flight-info-heading ul.dep-ul li{display:inline-block;margin-right:0.75rem}

.flight-details-box .flight-info-heading ul.dep-ul li .travel-date-div{background-color:rgb(255,237,209);padding:5px;font-size:13px;font-family:'poppins_semiboldregular'}

.flight-details-box .flight-info-heading ul.dep-ul li .travel-duration-div{font-size:13px;}

.flight-details-box .flight-info-heading .refundable-text{
    background-image: linear-gradient(to right, rgb(245, 81, 95), rgb(159, 4, 4));
    display: inline-block;
    color: #fff;
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
}

.flight-details-box .flight-info-heading a{font-size:13px;text-decoration: none;}

.flight-details-box .flight-info-content .flight-logo-div ul{list-style-type: none;padding:0px;margin-bottom:10px;}

.flight-details-box .flight-info-content .flight-logo-div ul li{display:inline-block;margin-right:10px;}

.flight-details-box .flight-info-content .flight-logo-div ul li .airline-name{color:#333;font-family:'poppins_semiboldregular';font-size:13px;}

.flight-details-box .flight-info-content .flight-logo-div ul li .airline-code{font-size:13px;}

.flight-details-box .flight-info-content .flightItenary{
    display: flex;
    justify-content: space-between;
    background-color: #f4f4f4;
    padding: 10px;
    flex-direction: row;
    position: relative;
}

.flight-details-box .flight-info-content .flightItenary .itineraryContent{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.flight-details-box .flight-info-content .flightItenary .itineraryContent:before{
    content: "";
    position: absolute;
    top: 15px;
    height: calc(100% - 35px);
    border-left: 2px dashed #666;
    left: 63px;
}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .flight-info-item{display:flex}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .flight-info-item .flight-time-div{
    min-width: 70px;
    justify-content: space-between;
    margin-right:10px;
    display:flex;
}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .flight-info-item .flight-time-div .flight-time{
    font-size:18px;
    color:#333;
    font-weight:bold;
}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .flight-info-item .flight-info{line-height:1.75}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .flight-info-item .flight-info .travel-date, .flight-details-box .flight-info-content .flightItenary .itineraryContent .flight-info-item .flight-info .airport-name{
    margin:0px;
    color:#333;
    font-family:'poppins_semiboldregular';
    font-size:14px;
}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .layoverCircle {
    border: 2px solid #666;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    display: inline-flex;
    background-color: #f4f4f4;
    position: relative;
    top: 5px;
    margin: 0px;
}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .layover {
    padding: 10px 20px;
    margin-left: 63px;
}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .layover ul{list-style-type: none;padding:0px;margin:0px}

.flight-details-box .flight-info-content .flightItenary .itineraryContent .layover ul li{display:inline-block;margin-right:10px;font-size:13px;}


.flight-details-box .conn-airport-divider{
    background-color: #888;
    color: #fff;
    padding: 5px 10px;
    font-size: 13px;
    margin: 15px 0px;
    text-align:center;
}
.passenger-section{
    background-color: #fff;
    border: 1px solid rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
}

.passenger-section h5{
    font-size: 1.2rem;
    font-family: 'poppins_semiboldregular';
    color: #444;
    margin-bottom:15px;
}



.edit-btn{
    background-color:#060e9f;
    border:1px solid #060e9f;
    color:#fff;
    padding:5px 20px;
    border-radius:5px;
    font-size:14px;
}

.edit-btn:hover{
    background-color: #1169e3;
    border:1px solid #1169e3;
    color:#fff;
}

.passenger-section table{
    width:100%;
    max-width:100%;
    border:1px solid #ccc;
    border-collapse:collapse;
}

.passenger-section table tr th{
    font-size:14px;
    padding:0.5rem;
    vertical-align: middle;
    border:1px solid #ddd;
    background-color:#eee;
    border-collapse:collapse;
}

.passenger-section table tr td{
    font-size:13px;
    padding:0.5rem;
    vertical-align: middle;
    border:1px solid #ddd;
    border-collapse:collapse;
}

.table-responsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media screen and (max-width:381px){
    .flight-details-box .flight-info-content .flightItenary .itineraryContent:before{height: calc(100% - 111px);}
}

@media screen and (min-width:382px) and (max-width:432px){
    .flight-details-box .flight-info-content .flightItenary .itineraryContent:before{height: calc(100% - 85px);}
}

@media screen and (min-width:433px) and (max-width:767px){
    .flight-details-box .flight-info-content .flightItenary .itineraryContent:before{height: calc(100% - 63px);}
}