.hotel-image{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    min-height: 260px;
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 16px;
}
.hotel-image img{
    width: 100%;
    height: 273px;
    min-height: 273px;
    max-height: 273px;
    object-fit: cover;
    border-radius: 16px;
}
.hotel-box{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #fff;
    border-radius: 16px;
    padding: 12px;
    float: left;
    width: 100%;
    
    
}
.hotel-box h5{
    font-weight: bold;
    margin-bottom: 0;
}
.hotel-rating{
    color: #ffc500;
}
a{
    text-decoration: none !important;
}
.hotel-location{
    padding: 9px 13px;
    background: #f4f8ff;
    margin: 8px 0 0;
    border-radius: 8px;
}
.hotel-location span{
    margin: 0 5px;
}
.hotel-list ul{
    text-align: left;
  float: left;
  width: 100%;
  margin: 8px 0 0;
  padding: 0;
}
.hotel-box ul li{
    text-decoration: none;
  list-style: none;
  display: block;
  float: left;
  text-align: left;
  margin-right: 10px;
  padding: 4px 7px;
  background: #f2f2f2;
  border-radius: 31px;
  font-size: 13px;
  margin-bottom: 8px;

}
.hotel-refund span{
    color: #f00;
}
.hotel-payment-card{
    border: 1px solid #e0e0e0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    min-height: 273px;
    border-radius: 16px;
    position: relative;
}
.hotel-total{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 26px;
    bottom: 15px;
}
.hotel-total h4{
    font-weight: bold;
    color: #161ea5;
    margin: 0;
}
.compare-hotel{
    position: absolute;
    right: 0;
    top: 0;
    background: #ffc107;
    color: #fff;
    padding: 6px 14px;
    border-radius: 1px 15px;
}
.choose-btn{
    background: #060e9f !important;
    margin: 15px;
    border: 1px solid #060e9f !important;
    border-radius: 35px !important;
    box-shadow: rgba(6, 14, 159, 0.14) 0px 8px 24px;
}
.hotel-rating-div{
    margin-top: 55px;
    font-size: 15px;
}

@media screen and (max-width:768px) {
    .hotel-image {
        margin-bottom: 15px;
    }
    .hotel-box  {
        margin-bottom: 15px;
    }
    .hotel-row{
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 11px;
        float: left;
        margin-bottom: 20px;
    }
}