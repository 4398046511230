.sort-btn{
    border: 2px solid #ccc;
    border-radius: 30px;
    padding: 4px 7px 7px;
    cursor: pointer;
  }
  
  @media screen and (max-width:768px) {
    .sort-btn{
      margin-bottom: 6px;
      font-size: 12px;
    }
  }