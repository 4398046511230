.hero-banner{
    background-image: url('../../assets/images/home-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 100px 0;
    position: relative;
}

.hero-banner-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.4);
}

.hero-banner-container{
    max-width:1170px;
    width:100%;
    padding-right:15px;
    padding-left:15px;
    margin:auto;
}

.search-engine-section {
    position: relative;
}

.search-engine-section .banner-text {
    text-align: center;
    margin-bottom: 30px;
}

.search-engine-section .banner-text h1 {
    color: #fff;
    font-family: poppins_semiboldregular;
    text-align: center;
    font-size: 3rem;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.search-engine-section .banner-text h6 {
    color: #fff;
    font-family: poppins_mediumregular;
    font-size: 20px;
    text-align: center;
    margin: 0;
    font-weight:normal;
}

.search-engine-section .search-box {
    background-color: #e2e2e2;
    padding: 20px;
    border-radius: 5px;
}

.search-box-tab-content .destination-fields{
    display: grid;
    gap:10px;
}

.home-input-field{
    background-color: #f7f7f7;
    position: relative;
    padding: 8px 15px 5px;
    border-radius: 10px;
    height: 70px;
    border: 1px solid #e7e8ea;
}

.home-input-field label{
        font-size:14px;
        margin-bottom:5px;
        font-family:poppins_mediumregular;
        display:block;
}

.home-input-field input, .home-input-field select{
    height: 34px;
    line-height:34px;
    border: none;
    background-color: transparent;
    width: 100%;
    outline: 0px;
    font-size:16px;
    color:#777;
}

.search-box .flight-top-row{
    display:flex;
    justify-content: space-between;
}

.search-box .journey-ul{list-style-type:none;padding:0;margin-bottom:0px}

.search-box .journey-ul li{display:inline-block;margin-right:10px}

.search-box .journey-ul li .btn-secondary-two .radio-checkmark{left:2px}

.search-box .journey-ul li .btn-secondary-two.custom-radio-btn{padding-left:23px;}

.search-box .journey-ul li .btn-secondary-two.custom-radio-btn .radio-checkmark:after{left:2px}

.search-box .journey-ul li:last-child{margin-right:0}

.search-box .journey-ul li .custom-radio-btn{display:block;position:relative;padding-left:20px;margin-bottom:20px;cursor:pointer;font-size:14px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;color:#4f6f8f;font-family:'poppins_semiboldregular';}

.search-box .journey-ul li .custom-radio-btn input{position:absolute;opacity:0;cursor:pointer}

.search-box .journey-ul li .radio-checkmark{position:absolute;top:-1px;left:0;height:16px;width:16px;background-color:#fff;border-radius:50%;border:1px solid #0d569a}

.search-box .journey-ul li .custom-radio-btn:hover input~.radio-checkmark{background-color:#fff;border:1px solid #0d569a}

.search-box .journey-ul li .custom-radio-btn input:checked~.radio-checkmark{background-color:#fff;border:1px solid #0d569a}

.search-box .journey-ul li .radio-checkmark:after{content:"";position:absolute;display:none}

.search-box .journey-ul li .custom-radio-btn input:checked~.radio-checkmark:after{display:block}

.search-box .journey-ul li .custom-radio-btn .radio-checkmark:after{top:2px;left:2px;width:8px;height:8px;border-radius:50%;background:#0d569a;border:1px solid #0d569a}

.flight-top-row .travel-sec{display:flex;gap:1.5rem}

.flight-top-row .travel-sec input, .flight-top-row .travel-sec select{border:none;background-color:transparent;
outline:0px;font-size:14px;color:#333;}

.flight-passenger-row{display:flex;justify-content: space-between;}

.flight-passenger-row .travellers-sec{display:grid;gap:10px;}



@media screen and (max-width:480px){
    .destination-fields-xs{
        grid-template-columns: 1fr;
    }

    .flight-passenger-row{display:block} 

    .travellers-sec-xs{grid-template-columns: 1fr;}

    .home-input-field.class-field{margin-top:0px !important}
}

@media screen and (min-width:481px) and (max-width:767px){
    .destination-fields-sm{
        grid-template-columns: 2fr 2fr;
    }

    .travellers-sec-sm{grid-template-columns: 2fr 2fr;}
    .flight-passenger-row{display:block}
    
}

@media screen and (min-width:768px) and (max-width:991px){
    .destination-fields-md{
        grid-template-columns: 2fr 2fr;
    }

    .travellers-sec-md{grid-template-columns: 2fr 2fr;}
}

@media screen and (min-width:992px) and (max-width:1199px){
    .destination-fields-lg{
        grid-template-columns: 2fr 2fr 1fr 1fr;
    }

    .travellers-sec-lg{grid-template-columns: 2fr 2fr;}
}

@media screen and (min-width:1200px){
    .destination-fields-xl{
        grid-template-columns: 2fr 2fr 1fr 1fr;
    }

    .travellers-sec-xl{grid-template-columns: 2fr 2fr;}
}