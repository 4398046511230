.booking-features-section{
    background-color:#fff;
    padding:50px 0px;
}

.booking-features-container{
    max-width:1170px;
    width:100%;
    padding-right:15px;
    padding-left:15px;
    margin:auto;
}

.booking-features-section h2{
    font-size: 2rem;
    font-family: 'poppins_mediumregular';
    text-align: center;
}

.booking-card{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
}

.booking-card-item{margin-top:40px;}

.booking-card-item .icon{
    text-align: center;
    margin: auto auto 15px;
    background-color: #ffeced;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .6s ease-in-out;
}

.booking-card-item .icon img{
    max-height:60px;
    display:block;
    margin:auto;
}

.booking-card-item h5{
    color:#333;
    font-size:18px;
    font-family: poppins_mediumregular;
    margin-bottom:10px;
    font-weight:normal;
    text-align:center;
}

.booking-card-item h6{
    font-size:14px;
    margin:0px;
    font-weight:normal;
    text-align:center;
    font-family: 'poppins_semiboldregular';
    color: #777;
}

.booking-card-item:hover .icon{
   
        transform: rotateZ(360deg);
   
}

@media screen and (max-width:480px){
    .booking-card-item{margin-top:20px;}
}

@media screen and (min-width:1200px){
    .booking-card{grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));}
}