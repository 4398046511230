.search-result-content {
    min-height: 80vh;
    background-color: #f5f5f5;
}


.modify-section {
    background-color: #ccc;
    padding: 15px;
}

.modify-section .trip_class ul {
    list-style-type: none;
    padding: 0px;
    margin-bottom: 15px;
}

.modify-section .trip_class ul li {
    display: inline-block;
    margin-right: 15px;
}

.modify-section .trip_class ul li select {
    background-color: transparent;
    border: none;
    outline: 0px;
}



.destination-head {
    margin-bottom: 20px;
}

.destination-head h3 {
    font-size: 1.25rem;
    font-family: 'poppins_semiboldregular';
    color: #333;
    margin-bottom: 5px;
}

.destination-head p {
    font-size: 13px;
    margin: 0px;
    color: #777
}

.search-result-content .flightResultbox {
    background-color: #fff;
    border: 1px solid rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 15px;
}

.flightResultbox .flight_price_slider h5 {
    margin-bottom: 5px;
    font-size: 18px;
    font-family: 'poppins_semiboldregular';
}

.flightResultbox .flight_price_slider p {
    font-size: 13px;
    color: #333;
    margin-bottom: 10px;
}

.flightResultbox .flight_price_slider .book-btn {
    background-color: #060e9f;
    border: 1px solid #060e9f;
    color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
}

.flightResultbox .flight_price_slider .book-btn:hover {
    background-color: #1169e3;
    border: 1px solid #1169e3;
    color: #fff;
}

.flightResultbox .flight-info .airline-logo p.airline-name {
    font-size: 13px;
    margin: 5px 0px 10px;
}

.flightResultbox .flight-info h5 {
    margin-bottom: 5px;
    font-size: 18px;
    font-family: 'poppins_semiboldregular';
}

.flightResultbox .flight-info .middle-section p.duration {
    font-size: 13px;
    margin: 0px;
    color: #333;
    font-family: 'poppinsregular'
}

.flightResultbox .flight-info .airline-brdr {
    width: 100%;
    background-color: #ccc;
    height: 2px;
    margin: 5px 0px;
}

.flightResultbox .flight-info .middle-section p.stop-text {
    font-size: 13px;
    color: #777;
    margin: 0px;
    font-family: 'poppinsregular'
}

.flightResultbox .flight-info h5 small {
    display: inline-block;
    text-align: center;
}

.flightResultbox .flight-info .middle-section p {
    font-size: 14px;
    color: #777;
    margin: 0px;
    font-family: 'poppins_semiboldregular'
}

.flightResultbox .flight-info h5 small span {
    display: block;
    color: red;
    font-size: 10px
}

.search-result-content .flightResultbox:hover {
    border: 1px solid #1169e3
}

.left-filter-section {
    margin-bottom: 15px;
}

.left-filter-section h3 {
    font-size: 1.25rem;
    font-family: 'poppins_semiboldregular';
    color: #333;
    margin-bottom: 10px;
}

.left-filter-section .reset-btn {
    padding: 5px 10px;
    width: 100%;
    box-shadow: none;
    border: 1px solid;
    border-radius: 5px;
    font-size: 15px;
}

.left-filter-section .filter-box {
    background-color: #fff;
    border: 1px solid rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    border-radius: 10px;
    padding: 15px;
    margin-top: 15px;
}

.left-filter-section .filter-box h5.filter-heading {
    font-size: 1rem;
    font-family: 'poppins_semiboldregular';
    margin-bottom: 10px;
}

.left-filter-section .filter-box .filter-body ul.checkbox-ul,
.left-filter-section .filter-box .filter-body ul.layover-ul,
.left-filter-section .filter-box .filter-body ul.travel-ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.left-filter-section .filter-box .filter-body ul.checkbox-ul li {
    display: block;
    margin-bottom: 5px;
}

.left-filter-section .filter-box .filter-body ul.checkbox-ul li:last-child {
    margin: 0px;
}

.left-filter-section .filter-box .filter-body ul.checkbox-ul li label {
    font-size: 12px;
    margin: 0px;
    font-family: 'poppinsregular';
    color: #333
}

.left-filter-section .filter-box .filter-body ul.checkbox-ul li label.price-label {
    font-family: 'poppins_semiboldregular'
}

.left-filter-section .filter-box .filter-body ul.checkbox-ul li label input {
    vertical-align: middle;
    margin-right: 5px;
}

.left-filter-section .filter-box .filter-body ul.layover-ul li img {
    max-height: 20px;
    display: block;
    margin: 0px auto 5px;
}

.left-filter-section .filter-box .filter-body ul.layover-ul li {
    display: inline-block;
    text-align: center;
    width: 25%;
    border: 1px solid #1169e3;
    border-right: none;
    cursor: pointer
}

.left-filter-section .filter-box .filter-body ul.layover-ul li:last-child {
    border-right: 1px solid #1169e3;
}

.left-filter-section .filter-box .filter-body ul.layover-ul li label {
    font-size: 12px;
    margin: 0px;
    cursor: pointer;
    display: block;
    padding: 5px;
}

.left-filter-section .filter-box .filter-body ul.layover-ul li label.active {
    background-color: #ddd
}

.left-filter-section .filter-box .filter-body ul.travel-ul li img {
    max-height: 20px;
    display: block;
    margin: 0px auto 5px;
}

.left-filter-section .filter-box .filter-body ul.travel-ul li {
    display: inline-block;
    text-align: center;
    width: 50%;
    border: 1px solid #1169e3;
    cursor: pointer
}

.left-filter-section .filter-box .filter-body ul.travel-ul li div {
    padding: 5px;
}

.left-filter-section .filter-box .filter-body ul.travel-ul li div.active {
    background-color: #ddd
}

.left-filter-section .filter-box .filter-body ul.travel-ul li label {
    font-size: 12px;
    margin: 0px;
    cursor: pointer;
    display: block;
}

.left-filter-section .filter-box .filter-body .journey-duration-sec {
    background-color: #f1f1f1;
    padding: 10px;
    margin-bottom: 15px;
}

.left-filter-section .filter-box .filter-body .journey-duration-sec:last-child {
    margin-bottom: 0px;
}

.left-filter-section .filter-box .filter-body .journey-duration-sec h6 {
    font-size: 14px;
    font-family: 'poppins_semiboldregular';
    color: #333;
    margin-bottom: 10px;
}

.horizontal-slider {
    width: 100%;
    height: 50px;
    margin: auto;
}

.example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 5px solid #060e9f;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
    font-size: 14px;
}

.example-thumb.active {
    background-color: #fff;
}

.example-track {
    position: relative;
    background: #ddd;
}

.example-track.example-track-0 {
    background: #ddd;
}

.example-track.example-track-1 {
    background: #060e9f;
}

.example-track.example-track-2 {
    background: #ddd;
}

.horizontal-slider .example-track {
    top: 20px;
    height: 4px;
}

.horizontal-slider .example-thumb {
    top: 12px;
    width: 20px;
    outline: none;
    height: 20px;
    line-height: 60px;
}



@media screen and (min-width:768px) {
    .flightResultbox .flight_price_slider {
        border-left: 1px solid #ccc;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}

.rs-picker-toggle-wrapper {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
    width: 100%;
}

.tooltip {
    position: absolute;
    inset: 0px 0px 0px 0px;
    transform: translate3d(47px, 617px, 0px);
}

button {
    border: none;
    background-color: #fff;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
        color: #000;
    }
}

.active {
    
    color: #000;
}