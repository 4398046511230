.detailsContent{
    min-height:80vh;
    background-color:#f5f5f5;
    padding:30px 0px;
}

.carAlert{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-left: 5px solid #060e9f;
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    margin-bottom: 15px;
}

.carAlert h2{
    margin-right: 20px;
    margin-bottom: 0px;
}

.carAlert p{
    margin:0px;
}

.carAlert p .colorText{
    color:#1169e3;
}

.carRentalDetails, .addressInfo, .passengerSection, .cardInfo, .fareSummary{
    background-color: #fff;
    border: 1px solid rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    border-radius: 15px;
    padding: 15px 15px;
    margin-bottom: 15px;
}

.carRentalDetails h2, .passengerSection h2, .cardInfo h2, .fareSummary h2{
    font-size: 22px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.carRentalDetails .carImage img{
    display: block;
    margin: 0px auto 10px;
    max-height: 200px;
    max-width: 100%;
}

.carRentalDetails .carName{
    font-size:20px;
    margin-bottom:10px;
    color:#333;
}

.carRentalDetails .carName small{
    font-weight:normal;
}

.carRentalDetails .carDestInfo{
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.carRentalDetails .carDestInfo h5{
    font-size: 16px;
    margin-bottom: 10px;
}

.carRentalDetails .carDestInfo p{
    font-size: 14px;
    margin: 0px;
}

.carRentalDetails h4{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.carRentalDetails h4 span{
    font-size:15px;
    color:green;
    font-weight:500;
}

.carRentalDetails .supplierInfo{
    list-style-type:none;padding:0px;margin:0px;
}

.carRentalDetails .supplierInfo li{
    display:inline-block;margin-bottom:5px;margin-right:15px;font-size:14px;
}

.carRentalDetails .supplierInfo li:last-child{
    margin-right:0px;
}

.carRentalDetails .supplierInfo li.supplierLogo img{max-height:18px;max-width:100%}

.carRentalDetails .supplierInfo li.supplierRating{
    background-color:#ffc107;
    padding:2px 10px;color:#fff;border-radius:4px;font-size:14px;
}

.carRentalDetails .carFacilities ul{list-style-type: none;padding:0px;margin:0px;}

.carRentalDetails .carFacilities ul li{display:inline-block;margin-bottom:10px;margin-right:20px;font-size:14px;}

.seatIcon{
    /* background-image: url('/src/images/seatIcon.svg'); */
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.bagIcon{
    /* background-image: url('/src/images/bagIcon.svg'); */
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.doorIcon{
    /* background-image: url('/src/images/doorIcon.svg'); */
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.fuelIcon{
    /* background-image: url('/src/images/fuelIcon.svg'); */
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.acIcon{
    /* background-image: url('/src/images/acIcon.svg'); */
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.transmissionIcon{
    /* background-image: url('/src/images/transmissionIcon.svg'); */
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}


.addressInfo .addressDiv{
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.addressInfo .addressDiv h6{
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
    color:#333;
}

.addressInfo .addressDiv p{
    margin:0px;
    font-size:14px;
}

.addressInfo h5{
    font-size: 16px;
    font-weight: 500;
    color: #1169e3;
    margin-bottom: 10px;
}

.addressInfo h5 span{
    font-weight: normal;
    color: #333;
}

.addressInfo a{
    text-decoration: none;
    font-size:14px;
    font-weight:500;
}

.addressInfo .carIncludes{
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.addressInfo .carIncludes h6{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.addressInfo .carIncludes ul{
    list-style-type: none;
    padding:0px;
    margin:0px;
}

.addressInfo .carIncludes ul li{
    font-size:14px;
    margin-bottom:10px;
}



.addressInfo .carIncludes ul li:last-child{
    margin:0px;
}

.carExtras{
    background-color: #fff;
    border: 1px solid rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    border-radius: 15px;
    padding: 15px 15px;
    margin-bottom: 15px;
}

.carExtras h2{
    font-size: 22px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.carExtras p{
    margin:0px;
    font-size:14px;
}

.carExtras p.heading{
    font-weight:500;
}

.carExtras p.heading span{
    color:#008000;
    font-weight:normal;
}

.carExtras select{
    padding:0.375rem 0.75rem;
    border:1px solid #ccc;
    font-size:14px;
    width:100px;
}

.carExtras table{
    margin:0px;
}

.carExtras table tr th{
    background-color:#eee;
}


.passengerSection .passengerDiv, .cardInfo .cardDiv{
    background-color: #f4f4f4;
    padding: 15px 15px 0px;
}

.passengerSection .passengerDiv label, .cardInfo .cardDiv label{
    font-size:14px;display:block;color:#666;margin-bottom:5px;color:#666;font-weight:500;
}

.passengerSection .passengerDiv input[type="text"],.passengerSection .passengerDiv select, .cardInfo .cardDiv input[type="text"], .cardInfo .cardDiv select{
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 0.375rem 0.75rem;
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    outline: 0px;
    box-shadow: none;
    margin-bottom: 15px;
    color: #666;
}

.fareSummary .paymentDiv{
    background-color: #f4f4f4;
    padding: 15px 15px 10px;
    margin-bottom: 15px;
}

.fareSummary .paymentDiv p{
    font-size:14px;
    margin-bottom:5px;
}

.fareSummary .paymentDiv p.leftText, .fareSummary .fareDiv p.leftText{
    text-align:left;
    font-weight:500;
}

.fareSummary .paymentDiv p.rightText, .fareSummary .fareDiv p.rightText{
    text-align:right;
}

.fareSummary .fareDiv{
    background-color: #888;
    padding: 5px 10px;
    margin-bottom:15px;
}

.fareSummary .fareDiv p{
    font-size:16px;
    color:#fff;
    margin:0px;
    font-weight:bold;
}

.fareSummary .checkLabel{
    font-size:14px;
    margin-bottom:10px;
}

.fareSummary .checkLabel input{
    vertical-align: middle;
    margin-right:5px;
}

.fareSummary .bookBtn{
    background-color: #060e9f;
    border: 1px solid #060e9f;
    color: #fff;
    font-size:14px;
    padding: 5px 20px;
    border-radius: 5px;
    width:100%;
}

.fareSummary .bookBtn:hover{
    background-color: #1169e3;
    border: 1px solid #1169e3;
    color: #fff;
}