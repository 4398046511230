.navbar {
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom:1px solid #ccc;
  padding:0px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: #107dbb;
}

.navbar-logo {
  color: #242424;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: #333;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 14px;
}

.nav-links:hover {
  color:#107dbb;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #333;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  background: #fff;
  padding: 5px;
}

@media screen and (max-width: 991px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #107dbb;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item{
    height:auto;
  }

  .nav-links {
    text-align: left;
    padding: 0.75rem 1rem;
    width: 100%;
    display: table;
    color:#fff;
    font-size:1rem;
  }

  .nav-links:hover {
    background-color: #107dbb;
    color: #fff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #333;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #107dbb;
    color: #fff;
    transition: 250ms;
  }
}


@media screen and (min-width:992px){
  .navbar-logo img{margin-top:-20px}
}


@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}



.b2b-header{
  background-color: #fff !important;
}

.b2b-header .navbar-barnd .logo{
    max-height: 55px;
}

.b2b-header .navbar-nav .nav-link{
    font-size: 13px;
    color: #000;
}

.b2b-header .navbar-nav .nav-link svg{
    margin-right: 5px;
    max-height: 11px;
}

.b2b-header .navbar-nav .dropdown-menu .dropdown-item{
    font-size: 13px;
}

.b2b-header .navbar-nav .dropdown-menu .dropdown-item svg{
    margin-right: 5px;
    max-height: 12px;
}

.b2b-header .navbar-nav .flagIco{
    max-height: 13px;
    margin-right: 5px;
}



.login-btn{
  background: #060e9f !important;
  min-width: 150px;
}
.login-modal .modal-dialog{
  max-width: 1200px;
}
.modal-wrapper{
  background: #f1f1f1;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 85px;
}
.modal-wrapper h4{
  font-weight: bold;
}

.login-img{
  width: 500px;
}
@media(max-width:768px){
  .modal-wrapper {
    padding: 23px 25px;
}
}
